import { API } from "aws-amplify";
import {
  getUserSimilarityEval,
  listSimilarUsers as listSimilarUsersQuery,
} from "graphql/queries";
import { USER_TYPES } from "lookup";

const listSimilarUsers = async (profileUser, limit = 5) => {
  if (!profileUser) {
    return [];
  }
  const { skills, username } = profileUser;
  const preFilter = {
    and: [
      { profileCompletion: { gte: 70 } },
      { userType: { eq: USER_TYPES.FREELANCER } },
    ],
  };

  if (skills?.length > 0) {
    preFilter.and.push({
      skillsName: { in: skills.map(({ name }) => name) },
    });
  }

  try {
    const { data } = await API.graphql({
      query: listSimilarUsersQuery,
      variables: {
        username,
        filter: { vectorSearchScore: { gte: 90 } },
        preFilter,
        limit,
      },
    });

    return data.listSimilarUsers;
  } catch (err) {
    console.log("Error while getting similar users", err);
    return [];
  }
};

const getUserSimilarityFeedback = async (
  referenceUserData,
  fieldsOfInterest,
  similarUserData
) => {
  if (!referenceUserData || !similarUserData) {
    return { score: null, feedback: "" };
  }
  try {
    const { data } = await API.graphql({
      query: getUserSimilarityEval,
      variables: {
        referenceUserData,
        fieldsOfInterest,
        similarUserData,
      },
    });
    return data.getUserSimilarityEval;
  } catch (err) {
    console.log("something went wrong when fetching similarity feedback");
    console.log(err);
    return { score: null, feedback: "" };
  }
};

const functionsToExport = {
  listSimilarUsers,
  getUserSimilarityFeedback,
};

export default functionsToExport;

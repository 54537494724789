import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { sortBy, indexOf } from "lodash";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { ReactComponent as OpenAIICon } from "images/openai-icon.svg";
import {
  getFormattedExperience,
  getFormattedTimeZone,
  getLocalTime,
} from "helpers/utils";
import UtilsLib from "utils/lib";
import { MAP_AVAILABILITY } from "constants";
import HeadShot from "../HeadShot";
import CountryFlag from "utils/CountryFlag";
import SvgIcon from "components/SvgIcon";

const SimilarUserCard = ({
  profileUser,
  similarUser,
  referenceUserData,
  similarUserData,
  fieldsOfInterest,
  fetchData,
}) => {
  const appsyncUserSkills = profileUser.skills;

  const {
    availability,
    careers,
    family_name,
    given_name,
    headshotKey,
    knownLanguages,
    locale,
    location,
    similarity,
    skills,
    timeZoneId,
    username,
    vectorSearchScore,
  } = similarUser;

  const [showMore, setShowMore] = useState({});
  const [localTime, setLocalTime] = useState(similarUser.localTime);
  const [loadingSimilarity, setLoadingSimilarity] = useState(false);
  const similarityFeedback = useRef(similarity.feedback || "");

  useEffect(() => {
    if (fetchData) {
      (async () => {
        setLoadingSimilarity(true);
        similarityFeedback.current = (
          await UtilsLib.User.getUserSimilarityFeedback(
            referenceUserData,
            fieldsOfInterest,
            similarUserData
          )
        ).feedback;
        setLoadingSimilarity(false);
      })();
    }
  }, [fetchData, referenceUserData, fieldsOfInterest, similarUserData]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeZoneId) {
        const localTime = getLocalTime(timeZoneId);
        setLocalTime(localTime);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeZoneId]);

  const handleToggleShowMore = (type) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [type]: !prevShowMore[type],
    }));
  };

  const experienceOrder = ["high", "medium", "low"];
  const sortedSkills = sortBy(skills, [
    // place common skills with appsync user skills first
    ({ name }) => (appsyncUserSkills?.some((sk) => sk.name === name) ? -1 : 1),
    ({ experience }) => {
      const experienceIndex = indexOf(experienceOrder, experience);
      return experienceIndex === -1 ? experienceOrder.length : experienceIndex;
    },
  ]);

  const renderShowMoreByField = (field, length) =>
    (length > (showMore[field] ? undefined : 3) || showMore[field]) && (
      <button
        className="text-sm cursor-pointer hover:underline"
        onClick={() => handleToggleShowMore(field)}
      >
        {showMore[field] ? "Show Less" : "Show More"}
      </button>
    );

  const renderUsernameLink = (username) => (
    <Link
      className="text-cyan-400 hover:underline visited:text-purple-60 font-medium text-sm"
      to={`/profile/${username}`}
      target="_blank"
    >
      @{username}
    </Link>
  );

  const SectionTitle = ({ title }) => (
    <p className="text-gray-900 text-lg sm:text-xl font-medium">{title}</p>
  );

  return (
    <div className="mx-auto relative bg-white rounded-[16px] shadow-md py-4">
      <div className="static bg-[#ECF6F9] w-full h-16 absolute top-0 z-0 rounded-t-[16px]">
        <Link
          to={`/profile/${username}`}
          target="_blank"
          className="w-full h-full"
        >
          <div className="flex justify-center items-center w-12 h-12 absolute top-2 right-2 rounded-full bg-white">
            <SvgIcon type="expandArrows" className="p-4 hover:p-3.5" />
          </div>
        </Link>
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        <HeadShot
          headshotKey={headshotKey}
          score={vectorSearchScore}
          className="!w-[100px] !h-[100px]"
          chipClassName="!w-8 !h-8"
        />
        <Link
          className="text-gray-800 hover:underline visited:!text-purple-60 text-lg sm:text-lg font-bold py-2"
          to={`/profile/${username}`}
          target="_blank"
        >
          @{username}
        </Link>
      </div>

      <p className="text-gray-700 text-sm md:text-base font-semibold text-center">
        {given_name} {family_name}
      </p>

      {(location?.countryCode || locale) && (
        <p className="flex justify-center items-center mb-4 gap-2 text-sm md:text-base">
          <CountryFlag country={location.countryCode.toLowerCase()} />
          {location.countryName || locale}
        </p>
      )}

      <div className="flex flex-col justify-center items-center mb-4 gap-y-0.5 text-sm md:text-base">
        <p className="text-coolGray-600 font-bold">
          {localTime ? `${localTime} local time` : <br />}
        </p>
        <div className="text-gray-400 text-sm">
          {localTime ? getFormattedTimeZone(timeZoneId) : <br />}
        </div>
      </div>
      <div className="px-4">
        {(loadingSimilarity || similarityFeedback.current) && (
          <>
            <hr className="my-2 md:my-4" />
            <div className="flex justify-between flex-col items-start gap-y-3">
              <div className="flex justify-between items-center w-full">
                <SectionTitle title="Details" />
                <OpenAIICon
                  title="Click to generate"
                  className={classNames("!w-[35px] !h-[35px]", {
                    "animate-spin": loadingSimilarity,
                  })}
                />
              </div>
              {similarityFeedback.current && (
                <div className="flex">
                  <p className="text-sm md:text-base font-medium text-left">
                    If you like{" "}
                    <span>{renderUsernameLink(profileUser.username)}</span> then
                    we think you'll also like{" "}
                    <span> {renderUsernameLink(username)}</span>{" "}
                    {similarityFeedback.current}
                  </p>
                </div>
              )}
            </div>
          </>
        )}
        <hr className="my-4" />
        <SectionTitle title="Summary" />
        <div className="flex justify-between ">
          <div>
            <div className="text-coolGray-600 font-semibold">
              {getFormattedExperience(careers)}
            </div>
            <div className="text-gray-500">Experience</div>
          </div>
          <div>
            <div className="lowercase first-letter:uppercase text-coolGray-600 font-semibold">
              {MAP_AVAILABILITY[availability] ?? "N/A"}
            </div>
            <div className="text-gray-500">Availability</div>
          </div>
        </div>
        <hr className="my-2" />
        <div>
          {!!skills?.length && (
            <>
              <div className="flex flex-col items-start">
                <SectionTitle title="Skills" />
                {renderShowMoreByField("skills", skills.length)}
              </div>
              <div className="flex flex-wrap mt-2">
                {sortedSkills
                  .slice(0, showMore["skills"] ? undefined : 3)
                  .map(({ name }) => (
                    <div
                      key={`skill-${name}`}
                      className="inline-block mr-2 mb-2 px-3 py-2 text-center rounded-full text-sm font-medium text-cyan-800 bg-cyan-50"
                    >
                      {name}
                    </div>
                  ))}
              </div>
            </>
          )}

          {!!knownLanguages?.length && (
            <>
              <hr className="my-2" />
              <div className="flex flex-col items-start">
                <SectionTitle title="Languages" />
                {renderShowMoreByField("languages", knownLanguages.length)}
              </div>
              <div className="flex flex-wrap gap-x-2 mt-2">
                {knownLanguages
                  ?.slice(0, showMore["languages"] ? undefined : 3)
                  .map(({ language, level }) => (
                    <div
                      key={`language-${language}_${level}`}
                      className="flex gap-2 text-sm"
                    >
                      <div className="text-coolGray-600 font-bold">
                        {language}
                      </div>
                      <div className="lowercase first-letter:uppercase text-gray-500">
                        {level}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

SimilarUserCard.propTypes = {
  availability: PropTypes.string,
  headshotKey: PropTypes.string,
  username: PropTypes.string,
  careers: PropTypes.array,
  knownLanguages: PropTypes.array,
  skills: PropTypes.array,
  appsyncUserSkills: PropTypes.array,
};

SimilarUserCard.defaultProps = {
  availability: "",
  headshotKey: "",
  username: "",
  careers: [],
  knownLanguages: [],
  skills: [],
  appsyncUserSkills: [],
};

export default SimilarUserCard;
